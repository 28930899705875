import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"

function Layout({ children }) {
  return (
    <AnimatePresence exitBeforeEnter>
      <Navbar />
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        {children}
        <Footer />
      </motion.div>
    </AnimatePresence>
  )
}

export default Layout
